<template>
    <div id="home" class="home container-fluid">
        <!-- Image main -->
        <section class="bg-image-custom" 
            :style="{'background-image' : `url(${img_main})`, 
                     'height' : `${height_main}px`, 
                     'min-height' : `100px` }">

            <div class="container h-100">
                <div class="row h-100 d-flex justify-content-center align-items-start">
                    <div class="col-12 mt-custom-text">
                        <div class="mb-3 mb-sm-5">
                            <span class="hc--title text-primary">
                                ¡Por un México con más <span class="text-warning">dueños</span>!
                            </span>
                        </div>

                        <div class="mb-4 d-none d-sm-block">
                            <span class="hc--description poppins-bold text-primary">
                                Compra, construye o remodela, <br>
                                te acompañamos en el proceso
                            </span>
                        </div>
                        
                        <div>
                            <button @click="redirectToSamePageSimple('title_carousel')" class="btn bg-warning bg-warning-gradient shadow-warning rounded-13">
                                <span class="hc--description-sm  text-white">
                                    Simula tu financiamiento
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Informative -->
        <section class="row my-5">

            <div class="col-12 px-0 mb-2 mt-5">
                <div class="mb-5 text-center">
                    <span class="hc--title-sm text-primary line-height-130">
                        Financiamiento inmobiliario sin complicaciones, <br class="d-none d-xl-block">
                        así es <span class="text-warning">HIR Casa</span>.
                    </span>
                </div>
                
                <div class="text-center pt-3 mb-3">
                    <span class="hc--description poppins-regular text-primary">
                        ¡Conoce nuestras ventajas!
                    </span>
                </div>
            </div>

            <div class="col-12">

                <div class="container px-0">
                    <div class="row d-flex justify-content-center">

                        <div class="col-12 col-md-6 col-xl-4 py-4 py-lg-5">
                            <center>
                                <div class="float-left">
                                    <img :src="img_flex">
                                </div>
                                
                                <div>
                                    <div class="mb-2">
                                        <span class="hc--title-sm poppins-bold text-primary">
                                            Flexibilidad
                                        </span>
                                    </div>
                                    <div>
                                        <span class="hc--description-sm text-primary">
                                            en la comprobación <br class="d-none d-xl-block"> de ingresos
                                        </span>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 py-4 py-lg-5">

                            <center>
                                <div class="float-left">
                                    <img :src="img_fina">
                                </div>
                                
                                <div>
                                    <div class="mb-2">
                                        <span class="hc--title-sm poppins-bold text-primary">
                                            Financiamos
                                        </span>
                                    </div>
                                    <div>
                                        <span class="hc--description-sm text-primary">
                                            desde $300,000 <br class="d-none d-xl-block"> hasta $15,000,000
                                        </span>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 py-4 py-lg-5">

                            <center>
                                <div class="float-left" style="height: 80px;">
                                    <img :src="img_mens" class="mnr-xl-4">
                                </div>
                                
                                <div>
                                    <div class="mb-2">
                                        <span class="hc--title-sm poppins-bold text-primary">
                                            Mensualidad
                                        </span>
                                    </div>
                                    <div>
                                        <span class="hc--description-sm text-primary">
                                            inicial desde $7,700 <br class="d-none d-xl-block"> por cada millón financiado
                                        </span>
                                    </div>
                                </div>
                            </center>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <!-- Carrousel -->
        <section id="title_carousel" class="row my-5">
            <!-- Title -->
            <div class="col-12 text-center px-5 py-5">
                <span class="hc--title-sm text-primary">
                    Conoce tu monto preautorizado en sólo un par de minutos.
                </span>
            </div>

            <!-- Carrousel -->
            <div class="col-12 bg-primary-gradient px-0 py-5">

                <div class="container px-0">
                    <div class="row">
                        <div class="col-12 px-0">
                            
                            <hc-carousel></hc-carousel>

                        </div>
                    </div>
                </div>

            </div>
        </section>

        <!-- Overexposure -->
        <section class="row section--overexposure">
            <div class="col-12">
                
                <div class="container bg-primary-gradient rounded-13">
                    <div class="row d-flex justify-content-center align-items-center text-center pt-4 pb-3 px-4">
                        <div class="col-12 mb-4">
                            <span class="hc--description poppins-bold text-white">
                                ¿Qué puedes adquirir con nuestro Financiamiento?
                            </span>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-xl border-sm-right my-3">
                            <div style="min-height: 120px;">
                                <div class="d-flex justify-content-center mb-2">
                                    <div style="max-width: 40px;">
                                        <img :src="ico_casa" class="img-fluid">    
                                    </div>
                                </div>
                                <span class="hc--description-sm text-white">
                                    Casa o departamento
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-xl border-md-right my-3">
                            <div style="min-height: 120px;">
                                <div class="d-flex justify-content-center mb-2">
                                    <div style="max-width: 40px;">
                                        <img :src="ico_local" class="img-fluid">    
                                    </div>
                                </div>
                                <span class="hc--description-sm text-white">
                                    Negocio o Local Comercial
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-xl border-sm-right without-md-border my-3">
                            <div style="min-height: 120px;">
                                <div class="d-flex justify-content-center mb-2">
                                    <div style="max-width: 40px;">
                                        <img :src="ico_terreno" class="img-fluid">    
                                    </div>
                                </div>
                                <span class="hc--description-sm text-white">
                                    Terreno
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-xl border-md-right my-3">
                            <div style="min-height: 120px;">
                                <div class="d-flex justify-content-center mb-2">
                                    <div style="max-width: 40px;">
                                        <img :src="ico_terreno" class="img-fluid">    
                                    </div>
                                </div>
                                <span class="hc--description-sm text-white">
                                    Construir o Remodelar
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-xl my-3">
                            <div style="min-height: 120px;">
                                <div class="d-flex justify-content-center mb-2">
                                    <div style="max-width: 60px;">
                                        <img :src="ico_preventa" class="img-fluid">    
                                    </div>
                                </div>
                                <span class="hc--description-sm text-white">
                                    Compra en Preventa
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>

        <!-- Testimonials -->
        <section class="row py-5">

            <div class="col-12 text-center px-5 mb-5">
                <div class="mb-3">
                    <span class="hc--title-sm poppins-regular text-primary">
                        Hemos financiado más de <span class="poppins-semibold">$30,000 millones</span> a más de 
                        <span class="poppins-semibold">12,450 clientes.</span>
                    </span>
                </div>
                
                <div>
                    <span class="hc--title text-primary">
                        ¡Tú puedes ser el siguiente!
                    </span>
                </div>
            </div>

            <div class="col-12 text-center px-5" v-if="false">
                <div>
                    <span class="hc--title-sm poppins-regular text-primary">
                        Ellos adquirieron su inmueble con el financiamiento de <span class="poppins-bold text-warning">HIR Casa</span>, 
                    </span>
                </div>
                
                <div>
                    <span class="hc--title text-primary">
                        ¡Tú puedes ser el siguiente!
                    </span>
                </div>
            </div>

            <div class="col-12" v-if="false">
                <div class="container px-0">
                    <div class="row d-flex justify-content-center">
                        <div class="col-12 col-md-6 col-xl-4 my-5 px-0">
                            <center>
                                <img :src="img_regina" class="img-fluid" style="min-width: 280px; ">

                                <div class="card_blur border rounded-13 pt-5 pb-4 px-2">

                                    <div class="mb-4">
                                        <span class="hc--title-sm text-primary">
                                            Regina Solís
                                        </span>
                                    </div>

                                    <div>
                                        <span class="hc--description-sm text-primary">
                                            Es cierto, te hacen un estudio socioeconómico y con base en ello, vas pagando. Yo ya fui beneficiada, me faltan cinco años para acabar de pagar ¡Ánimo!
                                        </span>
                                    </div>

                                    <div class="mt-3">
                                        <button class="btn btn-outline-warning rounded-13">
                                            <img :src="ico_play" class="align-middle mr-1">
                                            <span class="align-middle">Ver testimonio</span>
                                        </button>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 my-5 px-0">
                            <center>
                                <img :src="img_alvaro" class="img-fluid" style="min-width: 280px; ">

                                <div class="card_blur border rounded-13 pt-5 pb-4 px-2">

                                    <div class="mb-4">
                                        <span class="hc--title-sm text-primary">
                                            Álvaro Montes
                                        </span>
                                    </div>

                                    <div>
                                        <span class="hc--description-sm text-primary">
                                            Es cierto, te hacen un estudio socioeconómico y con base en ello, vas pagando. Yo ya fui beneficiada, me faltan cinco años para acabar de pagar ¡Ánimo!
                                        </span>
                                    </div>

                                    <div class="mt-3">
                                        <button class="btn btn-outline-warning rounded-13">
                                            <img :src="ico_play" class="align-middle mr-1">
                                            <span class="align-middle">Ver testimonio</span>
                                        </button>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 my-5 px-0">
                            <center>
                                <img :src="img_claudia" class="img-fluid" style="min-width: 280px; ">

                                <div class="card_blur border rounded-13 pt-5 pb-4 px-2">

                                    <div class="mb-4">
                                        <span class="hc--title-sm text-primary">
                                            Claudia Ayala
                                        </span>
                                    </div>

                                    <div>
                                        <span class="hc--description-sm text-primary">
                                            Es cierto, te hacen un estudio socioeconómico y con base en ello, vas pagando. Yo ya fui beneficiada, me faltan cinco años para acabar de pagar ¡Ánimo!
                                        </span>
                                    </div>

                                    <div class="mt-3">
                                        <button class="btn btn-outline-warning rounded-13">
                                            <img :src="ico_play" class="align-middle mr-1">
                                            <span class="align-middle">Ver testimonio</span>
                                        </button>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    // COMPONENTS
    import Carousel from '../components/Carousel.vue'

    // IMAGES
    import img_main from '../assets/images/main_image1.png'
        // line primary
    import ico_casa from '../assets/icons/casa.png'
    import ico_local from '../assets/icons/local.png'
    import ico_terreno from '../assets/icons/terreno.png'
    import ico_preventa from '../assets/icons/preventa.png'
        // informative section
    import img_flex from '../assets/images/flexibilidad.png'
    import img_fina from '../assets/images/financiamos.png'
    import img_mens from '../assets/images/mensualidad2.png'
        // testimonail section
    import img_regina from '../assets/images/regina.png'
    import img_alvaro from '../assets/images/alvaro.png'
    import img_claudia from '../assets/images/claudia.png'
    import ico_play from '../assets/icons/play.png'
    
    // JQuery
    import $ from 'jquery'


    export default {
        name: 'home',
        components: {
            'hc-carousel': Carousel
        },
        data() {
            return {
                device_width: 0,
                device_height: 0,

                // Image main
                img_main,
                img_main_width: 0,
                img_main_height: 0,
                height_main: 0,

                // icons of primary line
                ico_casa,
                ico_local,
                ico_terreno,
                ico_preventa,
                ico_play,

                // Informative section
                img_flex,
                img_fina,
                img_mens,
                
                // Testimonial section
                img_regina,
                img_alvaro,
                img_claudia,
            }
        },
        methods: {
            chargeImage() {
                let image_main = new Image()
                image_main.src = img_main
                this.img_main_width = (image_main.width !== 0) ? image_main.width : 1440
                this.img_main_height = (image_main.height !== 0) ? image_main.height : 666

                // First charge
                // image_main.onload = function() {
                //     alert(this.width + 'x' + this.height)
                // }
            },
            onResize() {
                //console.log('resize Home')
                this.device_width = document.documentElement.clientWidth
                this.device_height = document.documentElement.clientHeight
                // console.log(`[IMAGE] w: ${this.img_main_width}, h: ${this.img_main_height}`)

                const h_for_w = this.img_main_height / this.img_main_width
                this.height_main = this.device_width * h_for_w
            },
            redirectToSamePageSimple(element_id) {
                document.getElementById(element_id).scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'start', 
                    inline: 'start' 
                })
            },
 
        },
        async mounted() {
            window.addEventListener("resize", this.onResize)
            await this.chargeImage()

            this.onResize()
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.onResize)
        }
    }
</script>

<style lang="scss" scoped>
    #home {
        .mt-custom-text {
            margin-top: 2rem;
        }

        // Small devices (landscape phones, 576px and up)
        @media (min-width: 576px) {
            .mt-custom-text {
                margin-top: 3rem;
            } 
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) { 
            .mt-custom-text {
                margin-top: 5rem;
            } 
        }
        @media (min-width: 837px) { 
            .mt-custom-text {
                margin-top: 7rem;
            } 
        }
        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            .mt-custom-text {
                margin-top: 8rem;
            }
        }
        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
            .mt-custom-text {
                margin-top: 12rem;
            }
        }


        .section--overexposure {
            // margin-top: 50px;
            // margin-bottom: 50px;
        }

        @media (min-width: 768px) {
            .section--overexposure {
                // margin-top: -100px;
                // margin-bottom: 100px;
            }
        }

        @media (min-width: 1200px) {
            .mnr-xl-4 {
                margin-right: -4px;
            }
        }


        .card_blur {
            margin-left: -10px;
            margin-top: -20px; 
            width: 310px;
            backdrop-filter: blur(30px);
            mix-blend-mode: normal;
            box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.15);
            border-color: white !important; border-width: medium !important;
            z-index: 2;
        }

        @media (min-width: 576px) {
            .card_blur {
                margin-left: 0px;
            }
        }

        @media (min-width: 768px) {
            .card_blur {
                margin-top: -50px; 
                margin-left: 120px; 
            }
        }
    }
</style>
