<template>
    <div id="app">
        <hc-navbar></hc-navbar>
        <router-view></router-view>
        <hc-footer></hc-footer>
    </div>
</template>

<script>
    // Components
    import Navbar from './components/Navbar.vue'
    import Footer from './components/Footer.vue'

    export default {
        name: 'app_main',
        components: {
            'hc-navbar': Navbar,
            'hc-footer': Footer
        },
        data() {
            return {
                
            }
        },
        computed: {
            layout() {
                return this.$route.meta.layout
            }
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Poppins-Regular;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100vw !important;
        min-height: 100vh;
        text-align: left;
    }
</style>
