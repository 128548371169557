export default function sendRequestHir(url = null ,method = 'GET', params = null, body = null, query = false, zero = true) {
    // INIT
    let send_url = `${url}`
    let headers = CONFIG.server[CONFIG.env].headers

    let send_data = {
        method: method,
        headers: headers,
    }

    // PARAMS - BODY
    if(query) {
        let formBody = []
        for (var property in body) {
            var encodedKey = encodeURIComponent(property)
            var encodedValue = encodeURIComponent(body[property])
            formBody.push(encodedKey + "=" + encodedValue)
        }
        formBody = formBody.join("&")

        if(method == 'POST') {
            send_data.body = formBody
        }
        if(method == 'GET') {
            send_url = `${send_url}?${formBody}`
        }
    }
    else {
        if(params !== null) {
            send_data.params = JSON.stringify(params)
        }
        if(body !== null) {
            send_data.body = JSON.stringify(body)
        }
    }

    //console.log(send_url, 'send_url')
    

    // CONSOLES LOG
    this.$logEnv('SEND URL:', send_url)
    this.$logEnv('SEND DATA:', send_data)

    // FETCH
    let resp = fetch(send_url, send_data).then(res => res.json())
    .catch(async error => {
        this.$logEnv('ERROR:', error)
    })
    .then(async response => {
        this.$logEnv('RESPONSE:', (response !== null) ? response : 'Response is null')

        if(response.result !== 0) {
            return {
                data: [{result: 'ok'}],
                success: true
            }
        }
        else {
            return {
                errors: [{code: 'error', message: 'Ha ocurrido un error'}],
                success: false
            }
        }
    })

    return resp
}