<template>
    <footer id="le_footer" class="container-fluid bg-primary-gradient">
        <!-- Content -->
        <section class="row px-0 px-sm-5 py-5">
            <!-- Menú -->
            <div class="
                col-12 col-sm-12 col-lg-4 col-xl-4 
                d-flex justify-content-start align-items-center 
                px-4 px-xl-5
                border-lg-right">

                <div>
                    <img :src="img_logo" class="img-fluid mb-4">

                    <div style="max-width: 300px;">
                        <span class="hc--description-xs text-white">
                            Av. Desierto de los Leones No. 46, Nivel 1, Col. San Ángel, Álvaro Obregón, C.P. 01000, Ciudad de México.
                        </span>    
                    </div>
                    
                </div>
            </div>

            <!--  -->
            <div class=" 
                col-12 col-sm-6 col-lg-4 col-xl-4 
                mt-5 mt-lg-0
                d-flex justify-content-start align-items-center 
                px-4 px-xl-5
                border-sm-right">

                <div>
                    <div class="my-3">
                        <a href="https://hircasa.com/aviso-privacidad/" target="_blank" 
                            class="hc--description-xs poppins-semibold text-white">
                            Aviso de privacidad
                        </a>
                    </div>
                    <div class="my-3">
                        <a href="https://hircasa.com/terminos-y-condiciones/" target="_blank"
                            class="hc--description-xs poppins-semibold text-white">
                            Aviso legal
                        </a>
                    </div>
                    <div class="my-3" v-if="false">
                        <a href="https://hircasa.com/cookies" target="_blank"
                            class="hc--description-xs poppins-semibold text-white">
                            Cookies
                        </a>
                    </div>
                </div>
            </div>

            <!--  -->
            <div class=" 
                col-12 col-sm-6 col-lg-4 col-xl-4 
                mt-5 mt-lg-0
                d-flex justify-content-start align-items-center 
                px-4 px-xl-5">

                <div>
                    <div class="mb-3">
                        <span class="hc--description-xs poppins-semibold text-white">
                            Síguenos
                        </span>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/HIRCasaMexico" target="_blank" class="m-1">
                            <img :src="img_fb">    
                        </a>
                        <a href="https://twitter.com/HIRCASAMEXICO" target="_blank" class="m-1">
                            <img :src="img_tw">    
                        </a>
                        <a href="https://www.instagram.com/hircasa/" target="_blank" class="m-1">
                            <img :src="img_in">    
                        </a>
                        <a href="https://www.linkedin.com/company/3745335" target="_blank" class="m-1">
                            <img :src="img_li">    
                        </a>
                        <a href="https://www.youtube.com/user/HIRCASAMEXICO" target="_blank" class="m-1">
                            <img :src="img_yt">    
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    // IMAGES
    import img_logo from '../assets/images/logo_white.png'

    import img_fb from '../assets/images/social_media/fb.png'
    import img_tw from '../assets/images/social_media/tw.png'
    import img_in from '../assets/images/social_media/in.png'
    import img_li from '../assets/images/social_media/li.png'
    import img_yt from '../assets/images/social_media/yt.png'

    export default {
        name: 'le_footer',
        data() {
            return {
                img_logo,

                img_fb,
                img_tw,
                img_in,
                img_li,
                img_yt,
            }
        },
        methods: {
            redirect(name_route) {
                //console.log('redirect')
                if(this.$route.name !== name_route ) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: name_route
                    })
                }
            },
            redirectToSamePageSimple(name_route, element_id) {
                //console.log('redirectToSamePageSimple')
                document.getElementById(element_id).scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'start', 
                    inline: 'start' 
                })
            },
            redirectToSamePage(name_route, element_id) {
                //console.log(document.getElementById(element_id), 'redirectToSamePage')
                this.redirect(name_route)

                setTimeout(function() {
                    const yOffset = -1 * ($('#le_footer').height() + 32);
                    const element = document.getElementById(element_id);
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({top: y, behavior: 'smooth'});

                }, 300);
            },
            redirectToPage(url, target = "_blank") {
                if(target == "_blank") {
                    window.open(url, '_blank')
                }
                else {
                    window.location.replace(url)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

    #le_footer {
        
        .span-link {
            //color: #eee !important;
        }
        .span-link:hover {
            text-decoration: underline;
            text-decoration-style: simple;
        }
    }
</style>
