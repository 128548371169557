
//************************************************/
// Función para validar un RFC México
// Devuelve el RFC sin espacios ni guiones si es correcto
// Devuelve false si es inválido
// (debe estar en mayúsculas, guiones y espacios intermedios opcionales)


export default function isRFC(rfc, aceptar_generico = true) {
	const expresion = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
    var validado = rfc.match(expresion)

    // Coincide con el formato general del regex?
    if(!validado) { 
        return false
    }

    // Separar el dígito verificador del resto del RFC
    const digito_verificador = validado.pop()
    const rfc_sin_digito = validado.slice(1).join('')
    const len = rfc_sin_digito.length

   	// Obtener el digito esperado
    const diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ"
    const indice = len + 1
    var suma = 0
    var digito_esperado

    // Ajuste para persona moral
    suma = (len == 12) ? 0 : 481

    for (var i = 0; i < len; i++)
        suma += diccionario.indexOf(rfc_sin_digito.charAt(i)) * (indice - i);
    digito_esperado = 11 - suma % 11;
    if (digito_esperado == 11) digito_esperado = 0;
    else if (digito_esperado == 10) digito_esperado = "A";

    // El dígito verificador coincide con el esperado?
    // o es un RFC Genérico (ventas a público general)?
    if ((digito_verificador != digito_esperado)
        && (!aceptar_generico || rfc_sin_digito + digito_verificador != "XAXX010101000"))
        return false;
    else if (!aceptar_generico && rfc_sin_digito + digito_verificador == "XEXX010101000")
        return false;
    return rfc_sin_digito + digito_verificador;
}
