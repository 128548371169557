module.exports = {
	env: "prod",
	pixel: {
		token: 'EAARDZCB5ixRIBAOsGsbeuSS4mPoZAlcS304Dk8JRuDyqZBSMU6YdeXWiBFb0aajUuZAilEkTTQTNO9JJjB8YxzjZBajAM0DdtOcjOorp68ermmgdqBBtcZBtyRq8kIgENpFbsW1ZBbINmIvXBaZA2YrZBjONbgFURAeRfZClJZCwPZC58CcFxYiZAP3Tgst0e7ZCC4j5cZD',
		id: '457918109212085',
		version: 'v16.0'
	},
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'https://alige.bitrix24.es',
			pixel: 'https://graph.facebook.com',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json, charset=utf-8'
			}
		},
		dev: {
			page_url: 'http://hircasa.monocilindrero.co/',
			api: 'https://alige.bitrix24.es',
			pixel: 'https://graph.facebook.com',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json, charset=utf-8'
			}
		},
		prod: {
			page_url: 'http://hircasa.monocilindrero.co/',
			api: 'https://alige.bitrix24.es',
			pixel: 'https://graph.facebook.com',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json, charset=utf-8'
			}
		}
	}
}