import Vue from 'vue'
import Vuex from 'vuex'
import LocalStorage from 'localStorage'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        preauthorization_folio: null
    },
    getters: {
        getFolio: state => {
            state.preauthorization_folio = localStorage.getItem('hircasa@folio')
            return state.preauthorization_folio
        }
    },
    mutations: {
        setFolio: (state, new_folio) => {
            state.preauthorization_folio = new_folio
            localStorage.setItem('hircasa@folio', new_folio)
        }
    },
    actions: {
        deleteFolio: ({commit}) => {
            localStorage.removeItem('hircasa@folio')
        },
    },
    modules: {
    }
})