export default function logEnv(message, item = null, allow_enviroment = 'local') {
	
	if(CONFIG.env === allow_enviroment) {
		if(item === null) {
			console.log(message)
		}
		else {
			console.log(message, item)
		}
	}

    return null
}