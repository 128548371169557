<template>
	<div id="container_carousel" class="hc--carrousel">
        <div class="hc--carrousel__translate all_transitions" 
            :style="{'transform': `translateX(-${translate_slider}px)`}">

            <!-- Item Carousel -->
            <div class="px-1 px-md-5" :style="`min-width: ${width_item_slider}px`">
                <div class="hc--carrousel__item py-4 px-3 p-md-5">
                    <div class="align-items-end flex-column h-100" :class="(item_slider == 0) ? 'd-flex ' : 'd-none'">

                        <div class="w-100 text-center mb-4">
                            <span class="hc--description poppins-bold text-white">
                                Cuéntanos, ¿Para que necesitas el financiamiento?
                            </span>
                        </div>
                        
                        <div class="row d-flex justify-content-center w-100 mt-auto mb-4">

                            <div class="col-6 col-sm-6 col-md-4 
                                d-flex justify-content-center justify-content-xl-end my-2">

                                <div @click="selectFunding('comprar')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle  ${(funding_for === 'comprar') ? 'bg-white' : ''} mb-2`">
                                            <img :src="img_comprar" :class="(funding_for === 'comprar') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">
                                            Comprar
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-md-4 
                                d-flex justify-content-center my-2">

                                <div @click="selectFunding('construir')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle  ${(funding_for === 'construir') ? 'bg-white' : ''} mb-2`">
                                            <img :src="img_construir" :class="(funding_for === 'construir') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">Construir</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-6 col-sm-6 col-md-4 
                                d-flex justify-content-center justify-content-xl-start my-2">

                                <div @click="selectFunding('remodelar')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle ${(funding_for === 'remodelar') ? 'bg-white' : ''} mb-2`">
                                            <img :src="img_remodelar" :class="(funding_for === 'remodelar') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">Remodelar</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="w-100 text-center mt-auto">
                            <button @click="nextElement(1)" class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 my-1" v-if="false">
                                    Siguiente
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Item Carousel -->
            <div class="px-1 px-md-5" :style="`min-width: ${width_item_slider}px`">
                <div class="hc--carrousel__item py-4 px-3 p-md-5">
                    <div class="align-items-end flex-column h-100" :class="(item_slider == 1) ? 'd-flex ' : 'd-none'">

                        <div class="w-100 text-center mb-4">
                            <span class="hc--description poppins-bold text-white">
                                Para qué tipo de proyecto necesitas <br class="d-none d-xl-block">
                                el financiamiento de HIR Casa?
                            </span>
                        </div>
                        
                        <div class="row d-flex justify-content-center w-100 mt-auto mb-4">

                            <div class="col-6 col-sm-6 col-md-3 
                                d-flex justify-content-center my-2">

                                <div @click="selectProject('casa')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle  ${(type_project === 'casa') ? 'bg-white' : ''} mb-2`">
                                            <img :src="img_casa" :class="(type_project === 'casa') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">
                                            Casa / Dpto
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-md-3 
                                d-flex justify-content-center my-2">

                                <div @click="selectProject('local')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle ${(type_project === 'local') ? 'bg-white' : ''} mb-2 `">
                                            <img :src="img_local" :class="(type_project === 'local') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">
                                            Officina / Local
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-md-3 
                                d-flex justify-content-center my-2">

                                <div @click="selectProject('terreno')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle  ${(type_project === 'terreno') ? 'bg-white' : ''} mb-2`">
                                            <img :src="img_terreno" :class="(type_project === 'terreno') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">
                                            Terreno
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-md-3 
                                d-flex justify-content-center my-2">

                                <div @click="selectProject('preventa')" class="cursor-pointer">
                                    <div class="d-flex justify-content-center">
                                        <div :class="`div-circle  ${(type_project === 'preventa') ? 'bg-white' : ''} mb-2`">
                                            <img :src="img_preventa" :class="(type_project === 'preventa') ? 'invert-color-70' : ''">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="hc--description-sm poppins-semibold text-white">
                                            Preventa
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="w-100 text-center mt-auto">
                            <button @click="prevElement()" class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 mx-2 my-1">
                                    Anterior
                            </button>
                            <button @click="nextElement(2)" class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 mx-2 my-1" v-if="false">
                                    Siguiente
                            </button>
                        </div>

                    </div>
                </div>    
            </div>

            <!-- Item Carousel -->
            <div class="px-1 px-md-5" :style="`min-width: ${width_item_slider}px`">
                <div class="hc--carrousel__item py-4 px-3 p-md-5">
                    <div class="align-items-end flex-column h-100" :class="(item_slider == 2) ? 'd-flex ' : 'd-none'">

                        <div class="w-100 text-center mb-3">
                            <span class="hc--description poppins-bold text-white">
                                ¿Cuánto necesitas que te financiemos?
                            </span>
                        </div>
                        
                        <div class="w-100 d-flex justify-content-center mb-3">
                            <div style="max-width: 480px; width: 100%;">
                                <input v-model="amount_format"
                                    @input="onlyNumber($event, 'amount_format', true), 
                                        validateRange(), calculateAgesQuote()"
                                    type="text" placeholder="Ingresa monto" 
                                    class="input-thm-light full-width my-2 text-center poppins-bold" 
                                    style="color: #374066 !important;">
                            </div>
                        </div>

                        <!-- row -->
                        <div class="w-100 d-flex justify-content-center text-center mb-1">
                            <div>
                                <div class="mb-3">
                                    <span class="hc--description-sm poppins-semibold text-white">
                                        <div class="mb-2">
                                            ¿Tienes considerado un monto inicial?    
                                        </div>

                                        <button @click="changeHitch(false)"
                                            :class="`btn ${(!hitch) ? 'btn-white text-warning' : 'btn-outline-white'} 
                                            rounded-13 poppins-bold mx-2 mt-2 mt-sm-0`">
                                                No
                                        </button>
                                        <button @click="changeHitch(true)"
                                            :class="`btn ${(hitch) ? 'btn-white text-warning' : 'btn-outline-white'} 
                                            rounded-13 poppins-bold mx-2`">
                                                Si
                                        </button>
                                    </span>    
                                </div>
                                <div>
                                    <span class="hc--description-sm poppins-semibold text-white">
                                        ¿Cuánto puedes aportar inicialmente?
                                    </span>    
                                </div>
                            </div>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mb-1">
                            <span class="hc--description poppins-semibold text-white">
                                {{ range_quote | amountMoney(2, true) }}
                            </span>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mb-3">
                            <input id="range1" 
                                @change="calculateAgesQuote()"
                                v-model="range_quote" 
                                type="range" min="0" :max="amount85" :step="amount85 > 1000000 ? '1000' : '100'"
                                :disabled="!hitch"
                                class="input-range">
                        </div>

                        <!-- row -->
                        <div class="w-100 d-flex justify-content-center text-center mb-1">
                            <span class="hc--description-sm poppins-semibold text-white">
                                ¿En cuánto tiempo deseas liquidar tu financiamiento?
                            </span>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mb-1">
                            <span class="hc--description poppins-semibold text-white">
                                {{ ages_quote }} años
                            </span>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mb-4">
                            <input id="range2" 
                                @change="calculateRangeQuote()"
                                v-model="ages_quote" 
                                type="range" min="1" max="15" step="1"
                                :disabled="!hitch"
                                class="input-range input-range-fill">
                        </div>


                        <div class="w-100 d-flex justify-content-center text-center mb-3">
                            <span class="hc--description-sm poppins-semibold text-warning">
                                Considera que si deseas bajar el plazo de financiamiento debes aportar un monto inicial
                            </span>
                        </div>


                        <div class="w-100 text-center mt-2">
                            <button @click="prevElement()" class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 mx-2 my-1">
                                    Anterior
                            </button>
                            <button @click="nextElement(3)" class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 mx-2 my-1">
                                    Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Item Carousel -->
            <div class="px-1 px-md-5" :style="`min-width: ${width_item_slider}px`">
                <div class="hc--carrousel__item py-4 px-3 p-md-5">
                    <div class="align-items-end flex-column h-100" :class="(item_slider == 3) ? 'd-flex ' : 'd-none'">

                        <div class="w-100 text-center">
                            <span class="hc--description poppins-bold text-white">
                                ¿Te gustaría recibir asesoría personalizada?
                            </span>
                        </div>
                        
                        <div class="w-100 d-flex justify-content-center py-3">
                            <button @click="personalized_advice = false"
                                :class="`btn ${(!personalized_advice) ? 'btn-white text-warning' : 'btn-outline-light'} 
                                rounded-13 poppins-bold mx-3`">
                                    No
                            </button>
                            <button @click="personalized_advice = true"
                                :class="`btn ${(personalized_advice) ? 'btn-white text-warning' : 'btn-outline-light'} 
                                rounded-13 poppins-bold mx-3`">
                                    Si
                            </button>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mt-auto" v-if="personalized_advice">
                            <span class="hc--description-sm poppins-regular text-white">
                                Ingresa la siguiente información
                            </span>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mb-2" v-if="personalized_advice">
                            <div class="row">
                                <div class="col-4 px-1">
                                    <input v-model="name" type="text" placeholder="Nombre completo" 
                                        class="input-thm-light full-width my-2">
                                </div>
                                <div class="col-4 px-1">
                                    <input v-model="phone" @input="onlyNumber($event, 'phone')" 
                                        type="text" placeholder="Teléfono" maxlength="10"
                                        class="input-thm-light full-width my-2">
                                </div>
                                <div class="col-4 px-1">
                                    <input v-model="email"
                                        type="email" placeholder="Correo" required
                                        class="input-thm-light full-width my-2">
                                </div>
                                <div class="col-6 px-1">
                                    <input v-model="postal_code" @input="onlyNumber($event, 'postal_code')" 
                                        type="text" placeholder="CP de Residencia" maxlength="5"
                                        class="input-thm-light full-width my-2">
                                </div>
                                <!-- <div class="col-4 px-1">
                                    <select v-model="ocupation"
                                            class="input-thm-light full-width my-2">

                                        <option hidden disabled selected value="o">Ocupación</option>
                                        <option value="Bombero">Bombero</option>
                                        <option value="Dentista">Dentista</option>
                                        <option value="Enfermera">Enfermera</option>
                                        <option value="Médico">Médico</option>
                                        <option value="Actor">Actor</option>
                                        <option value="Diseñador">Diseñador</option>
                                        <option value="Albañil">Albañil</option>
                                        <option value="Carnicero">Carnicero</option>
                                    </select>
                                </div> -->
                                <div class="col-6 px-1">
                                    <!-- <input v-model="RFC" @input="onlyUpperCase('RFC', $event)"
                                        type="text" placeholder="RFC" maxlength="13"
                                        class="input-thm-light full-width my-2"> -->
                                    
                                        <b-form-select class="input-thm-light full-width my-2" style="height: 75%;" v-model="mediosComunicacionSelect" :options="mediosComunicacion"></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mt-auto" v-if="personalized_advice">
                            <span class="hc--description-sm poppins-regular text-white">
                                Selecciona tu medio preferido de Contacto
                            </span>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center py-3" v-if="personalized_advice">
                            <div @click="selectMeansContact('wpp')" class="cursor-pointer mx-2 mx-md-4">
                                <div :class="`div-circle-sm  ${(means_contact === 'wpp') ? 'bg-white' : ''}`">
                                    <img :src="ico_wpp" :class="`${(means_contact === 'wpp') ? 'invert-color-70' : ''}`">
                                </div>
                            </div>
                            <div @click="selectMeansContact('phone')" class="cursor-pointer mx-2 mx-md-4">
                                <div :class="`div-circle-sm  ${(means_contact === 'phone') ? 'bg-white' : ''}`">
                                    <img :src="ico_phone" :class="`${(means_contact === 'phone') ? 'invert-color-70' : ''}`">
                                </div>
                            </div>
                            <div @click="selectMeansContact('email')" class="cursor-pointer mx-2 mx-md-4">
                                <div :class="`div-circle-sm  ${(means_contact === 'email') ? 'bg-white' : ''}`">
                                    <img :src="ico_mail" :class="`${(means_contact === 'email') ? 'invert-color-70' : ''}`">
                                </div>
                            </div>
                        </div>

                        <div class="w-100 d-flex justify-content-center text-center mt-auto">
                            <div class="d-flex mb-1">
                                <input v-model="terms" type="checkbox" name="terms" class="input-check mr-1">
                                <span class="hc--description-sm poppins-regular text-white">
                                    He leído y acepto el 
                                    <a href="https://hircasa.com/aviso-privacidad/" target="_blank" class="text-warning">
                                        Aviso de privacidad
                                    </a>
                                </span>
                            </div>
                        </div>

                        <div class="w-100 text-center mt-auto">
                            <button @click="prevElement()" class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 mx-2 my-1">
                                    Anterior
                            </button>
                            <button @click="nextElement()" :disabled="!terms"
                                class="btn btn-warning btn-shadow-dark text-white 
                                rounded-13 mx-2 my-1">
                                    Siguiente
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Item Carousel -->
            <div class="px-1 px-md-5" :style="`min-width: ${width_item_slider}px`">
                <div class="hc--carrousel__item py-4 px-3 p-md-5">
                    <div class="align-items-end flex-column h-100" :class="(item_slider == 4) ? 'd-flex ' : 'd-none'">

                        <div class="w-100 text-center">
                            <span class="hc--description poppins-bold text-white">
                                {{ (personalized_advice) ? `¡${name}, e` : '!E' }}l inmueble de tus sueños está a punto de ser tuyo!
                            </span>
                        </div>

                        <div class="d-flex justify-content-center w-100 mt-auto">
                            <div style="max-width: 800px;">
                                <div class="row w-100">
                                    <div class="col-12 d-flex justify-content-between align-items-start px-0 my-2">
                                        <span class="hc--description-sm poppins-regular text-white">Valor del inmueble</span>

                                        <div>
                                            <span class="hc--description-sm poppins-regular align-middle text-white" 
                                                v-if="!updated_data">
                                                {{ amount | amountMoney(2, true) }}
                                            </span>
                                            <span class="hc--description-sm poppins-regular align-middle text-white" v-else>
                                                {{ modal_amount | amountMoney(2, true) }}
                                            </span>

                                            <br class="d-block d-sm-none">

                                            <!-- <div class="d-flex justify-content-end d-sm-inline-block">
                                                <div class="tooltip-container">
                                                    <img :src="ico_question" class="align-middle tooltip-trigger">

                                                    <div class="tooltip-one">
                                                        El contrato considera un factor de actualización fijo durante toda la vida de tu contrato, hoy en día del 9.5%. Este factor servirá para actualizar de forma creciente el valor de tu contrato y las mensualidades futuras, de tal modo que puedas tener las mensualidades más bajas cuando contratas. Este factor es un símil al costo financiero de nuestro financiamiento.
                                                    </div>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex justify-content-between align-items-start px-0 my-1">
                                        <span class="hc--description-sm poppins-regular text-white">Mensualidad Inicial</span>

                                        <div>
                                            <span class="hc--description-sm poppins-regular align-middle text-white">
                                                {{ mensualidad_inicial | amountMoney(2, true) }}
                                            </span>
                                            
                                            <br class="d-block d-sm-none">

                                            <!-- <div class="d-flex justify-content-end d-sm-inline-block">
                                                <div class="tooltip-container">
                                                    <img :src="ico_question" class="align-middle tooltip-trigger">

                                                    <div class="tooltip-one">
                                                        El contrato considera un factor de actualización fijo durante toda la vida de tu contrato, hoy en día del 9.5%. Este factor servirá para actualizar de forma creciente el valor de tu contrato y las mensualidades futuras, de tal modo que puedas tener las mensualidades más bajas cuando contratas. Este factor es un símil al costo financiero de nuestro financiamiento.
                                                    </div>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex justify-content-between align-items-start px-0 my-1">
                                        <span class="hc--description-sm poppins-regular text-white">Factor de actualización</span>

                                        <div>
                                            <span class="hc--description-sm poppins-regular align-middle text-white">
                                                {{ factor_atualizacion }}%
                                            </span>

                                            <br class="d-block d-sm-none">
                                            
                                            <div class="d-flex justify-content-end d-sm-inline-block">
                                                <div class="tooltip-container">
                                                    <img :src="ico_question" class="align-middle tooltip-trigger">

                                                    <div class="tooltip-one">
                                                        El contrato considera un factor de actualización fijo durante toda la vida de tu contrato, hoy en día del 11.000%. Este factor servirá para actualizar de forma creciente el valor de tu contrato y las mensualidades futuras, de tal modo que puedas tener las mensualidades más bajas cuando contratas. Este factor es un símil al costo financiero de nuestro financiamiento.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex justify-content-between align-items-start px-0 my-1">
                                        <span class="hc--description-sm poppins-regular text-white">Plazo de Financiamiento</span>

                                        <div>
                                            <span class="hc--description-sm poppins-regular align-middle text-white">
                                                1 hasta {{ ages_quote }} años
                                            </span>

                                            <br class="d-block d-sm-none">

                                            <!-- <div class="d-flex justify-content-end d-sm-inline-block">
                                                <div class="tooltip-container">
                                                    <img :src="ico_question" class="align-middle tooltip-trigger">

                                                    <div class="tooltip-one">
                                                        El contrato considera un factor de actualización fijo durante toda la vida de tu contrato, hoy en día del 9.5%. Este factor servirá para actualizar de forma creciente el valor de tu contrato y las mensualidades futuras, de tal modo que puedas tener las mensualidades más bajas cuando contratas. Este factor es un símil al costo financiero de nuestro financiamiento.
                                                    </div>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex justify-content-between align-items-start px-0 my-1">
                                        <span class="hc--description-sm poppins-regular text-white">Pago inicial</span>

                                        <div>
                                            <span class="hc--description-sm poppins-regular align-middle text-white">
                                                {{ monto_para_estrenar | amountMoney(2, true) }}
                                            </span>

                                            <br class="d-block d-sm-none">

                                            <!-- <div class="d-flex justify-content-end d-sm-inline-block">
                                                <div class="tooltip-container">
                                                    <img :src="ico_question" class="align-middle tooltip-trigger">

                                                    <div class="tooltip-one">
                                                        El contrato considera un factor de actualización fijo durante toda la vida de tu contrato, hoy en día del 9.5%. Este factor servirá para actualizar de forma creciente el valor de tu contrato y las mensualidades futuras, de tal modo que puedas tener las mensualidades más bajas cuando contratas. Este factor es un símil al costo financiero de nuestro financiamiento.
                                                    </div>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex justify-content-between align-items-start px-0 my-1">
                                        <span class="hc--description-sm poppins-regular text-white">Ingreso mensual requerido</span>

                                        <div>
                                            <span class="hc--description-sm poppins-regular align-middle text-white">
                                                {{ ingreso_minimo | amountMoney(2, true) }}
                                            </span>

                                            <br class="d-block d-sm-none">
                                            
                                            <!-- <div class="d-flex justify-content-end d-sm-inline-block">
                                                <div class="tooltip-container">
                                                    <img :src="ico_question" class="align-middle tooltip-trigger">

                                                    <div class="tooltip-one tooltip-bottom">
                                                        El contrato considera un factor de actualización fijo durante toda la vida de tu contrato, hoy en día del 9.5%. Este factor servirá para actualizar de forma creciente el valor de tu contrato y las mensualidades futuras, de tal modo que puedas tener las mensualidades más bajas cuando contratas. Este factor es un símil al costo financiero de nuestro financiamiento.
                                                    </div>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div class="w-100 text-center mt-auto">
                            <button @click="prevElement()" class="btn btn-outline-warning btn-shadow-dark bg-white 
                                rounded-13 mx-2 my-1">
                                    Anterior
                            </button>
                            <!-- <button @click="openModal()" class="btn btn-outline-warning btn-shadow-dark bg-white
                                rounded-13 mx-2 my-1">
                                    Volver a calcular
                            </button> -->
                            <button @click="finishCalculate()" class="btn btn-warning btn-shadow-dark text-white
                                rounded-13 mx-2 my-1">
                                    Finalizar
                            </button>
                        </div>


                    </div>
                </div>
            </div>

        </div>


        <div id="myModal" class="modal px-1 px-md-5"
            style="display: none; justify-content: center; align-items: center; background-color: #000000bb;">

            <!-- Modal content -->
            <div class="modal-custom px-1 px-md-5">
                <span @click="closeModal()" class="close dm-span-close text-red">&times;</span>
                
                <div class="row text-center py-4">

                    <div class="col-12 mb-3">
                        <span class="hc--description poppins-bold">
                            ¿Cuánto necesitas que te financiemos?
                        </span>
                    </div>
                    
                    <div class="col-12 d-flex justify-content-center mb-3">
                        <div style="max-width: 450px; width: 100%;">
                            <input 
                                v-model="modal_amount_format" 
                                @input="onlyNumber($event, 'modal_amount_format', true),
                                    validateRange('modal_'), calculateAgesQuote('modal_')"
                                type="text" 
                                placeholder="Ingresa monto" 
                                class="input-thm-light full-width my-2">
                        </div>
                    </div>

                    <!-- row -->
                    <div class="w-100 d-flex justify-content-center text-center mb-1">
                        <div>
                            <div class="mb-3">
                                <span class="hc--description-sm poppins-semibold mb-3">
                                    <div class="mb-2">
                                        ¿Tienes considerado un monto de Pago inicial?    
                                    </div>

                                    <button @click="modal_hitch = false"
                                        :class="`btn ${(!modal_hitch) ? 'btn-light text-warning' : 'btn-outline-light'} 
                                        rounded-13 poppins-bold mx-2 mt-2 mt-sm-0`">
                                            No
                                    </button>
                                    <button @click="modal_hitch = true"
                                        :class="`btn ${(modal_hitch) ? 'btn-light text-warning' : 'btn-outline-light'} 
                                        rounded-13 poppins-bold mx-2`">
                                            Si
                                    </button>
                                </span>    
                            </div>
                            <div>
                                <span class="hc--description-sm poppins-semibold">
                                    ¿Cuánto puedes aportar inicialmente?
                                </span>    
                            </div>
                        </div>
                    </div>

                    <div class="w-100 d-flex justify-content-center text-center mb-1">
                        <span class="hc--description poppins-semibold ">
                            {{ modal_range_quote | amountMoney(2, true) }}
                        </span>
                    </div>

                    <div class="w-100 d-flex justify-content-center text-center mb-3">
                        <input id="range3" 
                            @change="calculateAgesQuote('modal_')"
                            v-model="modal_range_quote" 
                            type="range" 
                            min="0" :max="modal_amount85" :step="modal_amount85 > 1000000 ? '1000' : '100'"
                            :disabled="!modal_hitch"
                            class="input-range bg-secondary">
                    </div>

                    <!-- row -->
                    <div class="w-100 d-flex justify-content-center text-center mb-1">
                        <span class="hc--description-sm poppins-semibold ">
                            ¿En cuánto tiempo deseas liquidar tu financiamiento?
                        </span>
                    </div>

                    <div class="w-100 d-flex justify-content-center text-center mb-1">
                        <span class="hc--description poppins-semibold ">
                            {{ modal_ages_quote }} años
                        </span>
                    </div>

                    <div class="w-100 d-flex justify-content-center text-center mb-4">
                        <input id="range4" 
                            @change="calculateRangeQuote('modal_')"
                            v-model="modal_ages_quote" 
                            type="range" min="1" max="15" step="1" 
                            :disabled="!modal_hitch"
                            class="input-range bg-secondary">
                    </div>

                    <div class="w-100 d-flex justify-content-center text-center mb-3">
                        <span class="hc--description-sm poppins-semibold text-warning">
                            Considera que si deseas bajar el plazo de financiamiento debes aportar un monto inicial
                        </span>
                    </div>


                    <div class="w-100 text-center mt-2">
                        <button @click="closeModal()" class="btn btn-warning btn-shadow-dark text-warning bg-white
                            rounded-13 mx-2 my-1">
                                Cancelar
                        </button>
                        <button @click="updateData()" class="btn btn-warning btn-shadow-dark text-white
                            rounded-13 mx-2 my-1">
                                Actualizar Cálculo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Store
    import _Store from '@/store'

	// IMAGES
		// item 1
	import img_comprar from '../assets/images/comprar.png'
    import img_construir from '../assets/images/construir.png'
    import img_remodelar from '../assets/images/remodelar.png'
		// item 2
    import img_casa from '../assets/images/casa.png'
    import img_local from '../assets/images/local.png'
    import img_terreno from '../assets/images/terreno.png'
    import img_preventa from '../assets/images/preventa.png'
    	// item 3
    import ico_wpp from '../assets/icons/wpp.png'
    import ico_phone from '../assets/icons/phone.png'
    import ico_mail from '../assets/icons/mail.png'
    import ico_question from '../assets/icons/question.png'

    // JQuery
    import $ from 'jquery'

    const { createHash } = require('crypto')


	export default {
		name: 'home',
		data() {
            return {
            	item_slider: 0,
                width_item_slider: 0,

                //step 1
                funding_for: null,
                //step 2
                type_project: null,
                //step 3
                hitch: false,
                //amount: null,
                amount_format: null,
                range_quote: 0,
                ages_quote: 15,
                //step 4
                personalized_advice: true,
                name: null,
                phone: null,
                email: null,
                postal_code: null,
                ocupation: 'o',
                RFC: null,
                mediosComunicacionSelect: null,
                mediosComunicacion: [
                    { value: null, text: 'Selecciona una opción' },
                    { value: 'Radio', text: 'Radio' },
                    { value: 'Camiones', text: 'Camiones' },
                    { value: 'Valla', text: 'Valla publicitaria' },
                    { value: 'Parabus', text: 'Parabus'}
                ],

                means_contact: null,
                terms: false,
                step3_complete: false,
                // modal 
                modal_amount_format: null,
                modal_hitch: false,
                modal_range_quote: 0,
                modal_ages_quote: 15,
                updated_data: false,


                mensualidad_inicial: 0,
                mensualidad_adelantada: 0,
                factor_atualizacion: 11,
                monto_para_estrenar: 0,
                ingreso_minimo: 0,

                // IMAGES
                img_comprar,
                img_construir,
                img_remodelar,

                img_casa,
                img_local,
                img_terreno,
                img_preventa,

                ico_wpp,
                ico_phone,
                ico_mail,
                ico_question,

                send_body: {},
                plazo: 0,

            }
        },
        computed: {
            translate_slider() {
                return this.width_item_slider * this.item_slider
            },
            amount() {
                let amount_prev = (this.amount_format == null) ? '0' : this.amount_format
                amount_prev = amount_prev.replace(/\$/g, '')
                amount_prev = amount_prev.replace(/\,/g, '')

                return Number(amount_prev)
            },
            amount85() {
                return (this.amount > 0) ? this.amount * 0.85 : 0
            },
            modal_amount() {
                let amount_prev = (this.modal_amount_format == null) ? '0' : this.modal_amount_format
                amount_prev = amount_prev.replace(/\$/g, '')
                amount_prev = amount_prev.replace(/\,/g, '')

                return Number(amount_prev)
            },
            modal_amount85() {
                return (this.modal_amount > 0) ? this.modal_amount * 0.85 : 0
            },
        },
        methods: {
            async onlyNumber(event, field, amount = false) {
                //console.log(this[field], '[field]')
                //console.log(event, event)

                if(amount) {
                    this[field] = event.target.value.replace(/[^\d]/g, '')
                    this.formatAmount(event, field)
                }
                else {
                    this[field] = event.target.value.replace(/[^\d]+/g, '')    
                }
                
            },
            onlyUpperCase(field, event) {
                this[field] = event.target.value.toUpperCase()
                this.$forceUpdate()
            },
            isEmail(value) {
                let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

                if(exp_reg.test(value)) {
                    return true
                }
                else {
                    return false
                }
            },
            formatAmount(e, field) {
                let input_value = this[field]
                input_value = input_value.replace(/\$/g, '')
                input_value = input_value.replace(/\,/g, '')

                //input_value = (input_value != '') ? input_value : '1'
                
                input_value = input_value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                input_value = `$${input_value}`

                this[field] = input_value
            },
            validateRange(type = '') {
                if(this[`${type}amount`] < this[`${type}range_quote`]) {
                    this[`${type}range_quote`] = this[`${type}amount`]
                }
                this.updateInputRange((type == '') ? 'range1' : 'range3')
            },
        	onResize() {
                // console.log('resize Carousel')
                this.device_width = document.documentElement.clientWidth
                this.device_height = document.documentElement.clientHeight
                // console.log(`[IMAGE] w: ${this.img_main_width}, h: ${this.img_main_height}`)

                // Size item Slider
                let container_carousel = $("#container_carousel").outerWidth(true)
                this.width_item_slider = container_carousel
            },
            showAlert(title = '', text = '', type = "error") {
                this.$swal({
                    icon: type,
                    title: title,
                    text: text,
                });
            },

            selectFunding(funding) {
                this.funding_for = funding

                let This = this
                setTimeout(function() {
                    This.nextElement(1)
                }, 200)
            },
            selectProject(type) {
                this.type_project = type

                let This = this
                setTimeout(function() {
                    This.nextElement(2)
                }, 200)
            },
            changeHitch(value) {
                if(value && (this.amount == null || isNaN(this.amount) || this.amount <= 0)) {
                    this.showAlert('Primero debe ingresar un monto')
                    return
                }
                this.hitch = value
            },
            selectMeansContact(social) {
                this.means_contact = social
            },
            

        	prevElement() {
                if(this.item_slider == 4 && this.step3_complete) {
                    this.item_slider = 2
                    return
                }

                this.item_slider = this.item_slider - 1
            },
            nextElement(step) {
                if(this.item_slider == 0 && this.funding_for == null) {
                    this.showAlert('Selecciona una opción')
                    return
                }
                if(this.item_slider == 1 && this.type_project == null) {
                    this.showAlert('Selecciona una opción')
                    return
                }
                if(this.item_slider == 2 && (this.amount == null || this.amount < 0)) {
                    this.showAlert('Ingresa un monto')
                    return
                }
                else if(this.item_slider == 2 && (this.amount < 300000)) {
                    this.showAlert('El monto debe ser mayor a $300,000')
                    return
                }
                else if(this.item_slider == 2 && (this.amount > 16000000)) {
                    this.showAlert('El monto debe ser menor a $16,000,000')
                    return
                }
                else if(this.item_slider == 2 && this.step3_complete) {
                    this.calculateFinal()
                    this.item_slider = 4
                    return
                }
                if(this.item_slider == 3 && this.personalized_advice) {
                    let error = false
                    let message_error = ''

                    if(this.name == null || this.name == '') {
                        message_error = 'Ingresa tu nombre completo'
                        error = true
                    }
                    if(this.phone == null || this.phone == '') {
                        message_error = (message_error == '') ? 'Ingresa tu teléfono' : `${message_error} <br> Ingresa tu teléfono`
                        error = true
                    }
                    if(this.email == null || this.email == '') {
                        message_error = (message_error == '') ? 'Ingresa tu correo' : `${message_error} <br> Ingresa tu correo`
                        error = true
                    }
                    else if(!this.isEmail(this.email)) {
                        message_error = (message_error == '') ? 'Ingresa tu correo valido' : `${message_error} <br> Ingresa tu correo valido`
                        error = true
                    }
                    if(this.postal_code == null || this.postal_code == '') {
                        message_error = (message_error == '') ? 'Ingresa CP de residencia' : `${message_error} <br> Ingresa CP de residencia`
                        error = true
                    }
                    if(this.mediosComunicacionSelect === null || this.mediosComunicacionSelect === '') {
                        message_error = (message_error == '') ? 'Ingresa tu Medio' : `${message_error} <br> Ingresa tu Medio`
                        error = true
                    }
                    
                    if(this.means_contact == null) {
                        message_error = (message_error == '') ? 'Selecciona un medio de contacto' : `${message_error} <br> Selecciona un medio de contacto`
                        error = true
                    }
                    if(!this.terms) {
                        message_error = (message_error == '') ? 'Debes aceptar los terminos y condiciones' : `${message_error} <br> Debes aceptar los terminos y condiciones`
                        error = true
                    }

                    if(error) {
                        this.showAlert(message_error)
                        return    
                    }
                    else {
                        this.step3_complete = true
                    }
                }

                this.item_slider = this.item_slider + 1

                if(this.item_slider == 4) {
                    this.calculateFinal()
                }
            },

            calculateAgesQuote(type = '', fixed = true) {
                let mensualidad_inicial = (this[`${type}amount`] * .767955) / 100
                let mensualidad_adelantada = (this[`${type}amount`] * .636755) / 100
                // console.log(mensualidad_inicial, 'mensualidad_inicial')
                // console.log(mensualidad_adelantada, 'mensualidad_adelantada')

                // meses que se adelantarian de haber una aportacion inicial
                let cuotas_adelantadas = (this[`${type}range_quote`] < mensualidad_inicial) ? 0 : 
                    ((this[`${type}range_quote`] - mensualidad_inicial) / mensualidad_adelantada) + 1
                // console.log(cuotas_adelantadas, 'cuotas_adelantadas')

                let años_adelantados = Math.floor(cuotas_adelantadas / 12)
                // console.log(años_adelantados, 'años_adelantados')

                this.plazo = 15 - años_adelantados
                // console.log(plazo, 'plazo')

                this[`${type}ages_quote`] = this.plazo
                // Update front
                this.updateInputRange((type == '') ? 'range2' : 'range4')
            },
            calculateRangeQuote(type = '', fixed = true) {
                let mensualidad_inicial = (this[`${type}amount`] * .767955) / 100
                let mensualidad_adelantada = (this[`${type}amount`] * .636755) / 100
                let years_diminished = 15 - this[`${type}ages_quote`]
                let months_diminished = (years_diminished * 12) - 1

                let cantidad = mensualidad_inicial
                for(let c = 0; c < (months_diminished); c++) {
                    cantidad += mensualidad_adelantada
                }

                this[`${type}range_quote`] = (fixed) ? cantidad.toFixed(2) : cantidad

                // Update front
                this.updateInputRange((type == '') ? 'range1' : 'range3')
            },
            updateInputRange(input_id = 'range1') {
                // Update front
                let incress_c = 0
                let interval_update_range_quote = setInterval(function() {
                    document.getElementById(input_id).oninput()
                    incress_c += 10

                    if(incress_c > 500) {
                        clearInterval(interval_update_range_quote)     
                    }
                }, 10);
            },

            calculateFinal(type = '') {
                // console.log('Valores iniciales')
                let amount_final = this[`${type}amount`]
                // console.log(amount_final)
                // console.log(this.range_quote)
                // console.log(this.ages_quote)


                this.mensualidad_inicial = ((amount_final * .767955) / 100).toFixed(2)
                this.mensualidad_adelantada = ((amount_final * .636755) / 100).toFixed(2)

                // // meses que se adelantarian de haber una aportacion inicial
                let cuotas_adelantadas = (this.range_quote < Number(this.mensualidad_inicial)) ? 0 : 
                    (((this.range_quote - Number(this.mensualidad_inicial)) / Number(this.mensualidad_adelantada)) + 1).toFixed(2)
                // console.log(cuotas_adelantadas, 'cuotas_adelantadas')


                let años_adelantados = Math.floor(cuotas_adelantadas / 12)
                // console.log(años_adelantados, 'años_adelantados')

                this.plazo = 15 - años_adelantados
                // console.log(plazo, 'plazo')

                // let total_mensualidades = 0

                
                let h1 = (amount_final * .3833653).toFixed(2)
                let h2 = (amount_final / 3).toFixed(2)
                let h3 = (amount_final * .0427).toFixed(2)
                let h4 = (h3 * .16).toFixed(2)
                let h5 = (amount_final * .0005).toFixed(2)
                // console.log(`h1: ${h1}; h2: ${h2}; h3: ${h3}; h4: ${h4}; h5: ${h5};`)

                let g1 = 0
                let g2 = (amount_final / 180).toFixed(2)
                let g3 = (amount_final * (.0014 / 2)).toFixed(2)
                let g4 = (Number(g3) * .16).toFixed(2)
                g1 = (Number(g2) + Number(g3) + Number(g4)).toFixed(2)
                // console.log(`g1: ${g1}; g2: ${g2}; g3: ${g3}; g4: ${g4};`)

                let f1 = (180 - (this.ages_quote * 12)) - Number(cuotas_adelantadas)
                let f2 = 60 - Number(cuotas_adelantadas)
                // console.log(`f1: ${f1}; f2: ${f2};`)

                let e1 = (h1 - this.range_quote).toFixed(2)
                let e2 = (g2 * f2).toFixed(2)
                let e3 = (g3 * f2).toFixed(2)
                let e4 = (g4 * f2).toFixed(2)

                let monto_estreno = h1
                let aportacion_inicial = this.range_quote

                let monto_pendiente = 0
                let aportacion_capital = 0
                let gastos_administracion = 0
                let iva_gastos_administracion = 0
                let seguro_vida = 0

                if(aportacion_inicial > monto_estreno) {
                    monto_pendiente = 0
                    aportacion_capital = 0
                    gastos_administracion = 0
                    iva_gastos_administracion = 0
                    seguro_vida = 0
                }
                else if(aportacion_inicial > Number(this.mensualidad_inicial)) {
                    monto_pendiente = e1
                    aportacion_capital = e2
                    gastos_administracion = e3
                    iva_gastos_administracion = e4
                    seguro_vida = 0
                }
                else {
                    monto_pendiente = h1
                    aportacion_capital = h2
                    gastos_administracion = h3
                    iva_gastos_administracion = h4
                    seguro_vida = h5
                }
                this.ingreso_minimo = (Number(this.mensualidad_inicial) * 3.5).toFixed(2)
                // console.log(this.ingreso_minimo, 'ingreso_minimo')

                this.monto_para_estrenar = monto_estreno
                let preauthorization_folio = Math.random().toString(36).slice(2).substring(0,7).toUpperCase()
                _Store.commit('setFolio', preauthorization_folio)

                this.send_body = {
                        //TEMPORALES
                        'FIELDS[TITLE]': 'Hir Casa AB/Testing',
                        // Contact data
                        // 'FIELDS[EMAIL]': (this.personalized_advice) ? this.email : null, 
                        // 'FIELDS[PHONE]': (this.personalized_advice) ? this.phone : null, 
                        'FIELDS[NAME]': (this.personalized_advice) ? this.name : null, 
                        'FIELDS[EMAIL]': null, 
                        'FIELDS[PHONE]': null, 
                        'FIELDS[UF_CRM_1669997010]': (this.personalized_advice) ? this.email : null, // email
                        'FIELDS[UF_CRM_1669996971]': (this.personalized_advice) ? this.phone : null, // phone

                        // Types
                        'FIELDS[UF_CRM_1663874597]': this.funding_for, 
                        'FIELDS[UF_CRM_1663874623]': this.type_project, 
                        
                        'FIELDS[POST]': (this.personalized_advice) ? this.postal_code : null, 
                        'FIELDS[UF_CRM_1663874688]': (this.personalized_advice) ? this.RFC : null, 
                        'FIELDS[UF_CRM_1663874707]': (this.personalized_advice) ? this.means_contact : null, 
                        // UTM
                        'FIELDS[UTM_SOURCE]': null, 
                        'FIELDS[UTM_CAMPAIGN]': (this.personalized_advice) ? this.mediosComunicacionSelect : null, 
                        'FIELDS[UF_MEDIUM]': null, 
                        //
                        'FIELDS[UF_CRM_1663874743]': amount_final, 
                        'FIELDS[UF_CRM_1663874763]': this.plazo, 
                        // Data calculate
                        'FIELDS[UF_CRM_1663874990]': this.mensualidad_inicial,
                        'FIELDS[UF_CRM_1663875417]': this.mensualidad_adelantada,
                        'FIELDS[UF_CRM_1663875474]': cuotas_adelantadas,
                        'FIELDS[UF_CRM_1663875487]': monto_pendiente,
                        'FIELDS[UF_CRM_1663875563]': aportacion_capital,
                        'FIELDS[UF_CRM_1663875586]': gastos_administracion,
                        'FIELDS[UF_CRM_1663875615]': iva_gastos_administracion,
                        'FIELDS[UF_CRM_1663875629]': seguro_vida,
                        'FIELDS[UF_CRM_1663875652]': this.ingreso_minimo,
                        'FIELDS[UF_CRM_1663875678]': this.monto_para_estrenar,
                        'FIELDS[UF_CRM_1664978963]': preauthorization_folio
                    }
                    console.log("sendbody: ",this.send_body)
            },

            async finishCalculate() {

                // datos mandados con la solicutud POST
                let _datos = {
                    "nombre":(this.personalized_advice) ? this.name : null,
                    "paterno":"",
                    "materno":"",
                    "correo":this.personalized_advice ? this.email : null,
                    "telefono":this.personalized_advice ? this.phone : null,
                    "zona": (this.personalized_advice) ? this.postal_code : null, 
                    "vip":1,
                    "utm_source":"",
                    "utm_medium":(this.personalized_advice) ? this.mediosComunicacionSelect : null,
                    "utm_campaign":"",
                    "utm_tag":"",
                    "utm_tipo":"",
                    "origen": this.personalized_advice ? this.means_contact : null,
                }

                fetch('https://api-xapp.softbd.com.mx/Hircasa/Message/step1', {
                method: "POST",
                body: JSON.stringify(_datos),
                headers: {"Content-type": "application/json; charset=UTF-8"}
                })
                .then(response => response.json()) 
                .then(responseStep1 =>{ 
                    console.log(responseStep1.data.id)
                    let _datosStep2 = {
                        "lead": responseStep1.data.id,
                        "meses_estreno": this.ages_quote,
                        "inmueble": this.type_project,
                        "tipo_calculo": "",
                        "ingresos": "",
                        "monto": this.amount_format,
                        "mensualidad": this.mensualidad_inicial,
                        "inmueble_identificado": this.type_project,
                        "enganche": this.range_quote,
                        "zona": (this.personalized_advice) ? this.postal_code : null,
                        "campaign_id": 0,
                        "financiamiento": this.plazo,
                        "medio_ingreso": this.ocupation,
                        "uso": "",
                        "estado_civil": "",
                        "estado_estreno": "",
                        "genero": "",
                        "edad": "",
                        "vip": 1,
                    }

                    fetch('https://api-xapp.softbd.com.mx/Hircasa/Message/step2', {
                    method: "POST",
                    body: JSON.stringify(_datosStep2),
                    headers: {"Content-type": "application/json; charset=UTF-8"}
                    })
                    .then(response => response.json()) 
                    .then(json => {
                        console.log(json)
                        this.redirect('thank_you')
                    })
                    .catch(err => console.log(err));
                })
                .catch(err => console.log(err));

                // if(this.personalized_advice) {
                //     let datos_financieros = {
                        
                //     }
                //     console.log(datos_financieros)
                //     let response2 = await this.$sendRequestHir('https://api-xapp.softbd.com.mx/Hircasa/Message/step1', 'POST', null, datos_financieros, true)
                    
                //     console.log(response2)

                //     if(response.success && response.data) {
                //         let bodyHir = {
                //             primerNombre: (this.personalized_advice) ? this.name : null,
                //             segundoNombre: null,
                //             apellidoPaterno: null,
                //             apellidoMaterno: null,
                //             telefono: this.personalized_advice ? this.phone : null,
                //             correo: this.personalized_advice ? this.email : null,
                //             cp: this.personalized_advice ? this.postal_code : null,
                //             urlutm: this.$route.fullPath == "/" ? "" : this.$route.fullPath,
                //             tipoInmueble: this.type_project,
                //             montoAporta: this.monto_para_estrenar,
                //             tiempoLiquida: this.ages_quote,
                //             contactarPor: this.means_contact,
                //             entrada: this.mediosComunicacionSelect,
                //         }
                //         let bodyHir2 = {
                //             nombre: (this.personalized_advice) ? this.name : null,
                //             paterno: null,
                //             materno: null,
                //             telefono: this.personalized_advice ? this.phone : null,
                //             correo: this.personalized_advice ? this.email : null,
                //             zona: this.personalized_advice ? this.postal_code : null,
                //             vip: "1",
                //             monto: this.monto_para_estrenar,
                //             utm_medium: this.mediosComunicacionSelect,
                //         }

                //         let response2 = await this.$sendRequestHir('https://clickalia-cross.hircasa.com/Clickalia/ProspectoBase', 'POST', null, bodyHir, true)
                //         console.log(response2);
                        
                //         let response3 = await this.$sendRequestHir('https://api-xapp.softbd.com.mx/Hircasa/Message/step2', 'POST', null, bodyHir2, true)
                //         console.log(response3);

                        
                //         this.redirect('thank_you')
                //     }
                //     else if(!response.success && response.errors) {
                //         // Estatus incorrecto en la respuesta
                //         let message = ''
                //         for(let c = 0; c < response.errors.length; c++) {
                //             message = `${message} \n ${response.errors[c].message}`
                //         }
                        
                //         alert(message)
                //     }
                //     else {
                //         // Se perdio la respuesta
                        
                //     }
                    
                // }
                // else {
                //     window.location.reload()
                // }
                
            },

            redirect(route_name) {
                if(this.$route.name !== route_name ) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: route_name
                    })
                }
            },

            openModal(url) {
                var modal = document.getElementById("myModal");
                modal.style.display = "flex";
            },
            closeModal() {
                var modal = document.getElementById("myModal");
                modal.style.display = "none";
            },
            updateData() {
                //this.amount = this.modal_amount
                this.updated_data = true
                this.hitch = this.modal_hitch
                this.range_quote = this.modal_range_quote
                this.ages_quote = this.modal_ages_quote

                this.calculateFinal('modal_')
                this.closeModal()
            }
        },
        async mounted() {
            window.addEventListener("resize", this.onResize)
            this.onResize()

            document.getElementById("range1").oninput = function() {
                let value = ( this.value - this.min ) / ( this.max - this.min ) * 100
                this.style.background = 'linear-gradient(to right, #FFA000 0%, #FFA000 ' + value + '%, #fff ' + value + '%, white 100%)'
            }
            document.getElementById("range2").oninput = function() {
                let value = ( this.value - this.min ) / ( this.max - this.min ) * 100
                this.style.background = 'linear-gradient(to right, #FFA000 0%, #FFA000 ' + value + '%, #fff ' + value + '%, white 100%)'
            }
            document.getElementById("range3").oninput = function() {
                let value = ( this.value - this.min ) / ( this.max - this.min ) * 100
                this.style.background = 'linear-gradient(to right, #FFA000 0%, #FFA000 ' + value + '%, #C4C4C4 ' + value + '%, #C4C4C4 100%)'
            }
            document.getElementById("range4").oninput = function() {
                let value = ( this.value - this.min ) / ( this.max - this.min ) * 100
                this.style.background = 'linear-gradient(to right, #FFA000 0%, #FFA000 ' + value + '%, #C4C4C4 ' + value + '%, #C4C4C4 100%)'
            }

            document.getElementById("range2").oninput()
            document.getElementById("range4").oninput()
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.onResize)
        }
	}
</script>

<style lang="scss" scoped>
	.hc--carrousel {
        position: relative;
        flex-wrap: wrap;

        &__translate {
            display: inline-flex;
            justify-content: flex-start;
        }

        &__item {
            background-color: #FFFFFF33; 
            border: 1px solid white;
            border-radius: 13px;
            min-height: 616px; 
            height: 100%;
            // max-width: 1000px;
            // width: 100%;
            // width: 1000px;
            // min-width: 100%;

            position: relative;
            overflow: hidden;
            transition: box-shadow .1s;

            // background: linear-gradient(#32327b,#442768);
            // box-shadow: inset 0 0 3px 3px #ed1e79, inset 0 0 30px 5px #ed1e79;
        }
    }


    .input-range {
        // background: linear-gradient(to right, #FFA000 0%, #FFA000 0%, #fff 50%, #fff 100%);
        -webkit-appearance: none;
        width: 100%;
        max-width: 600px;
        height: 7px;
        border-radius: 5px;  
        background: white;
        outline: none;
        opacity: 1;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .input-range:disabled {
        opacity: .5;
    }

    .input-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #FFA000;
        cursor: pointer;
    }

    .input-range::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #FFA000;
        cursor: pointer;
    }





    /* Hide the browser's default checkbox */
    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
    }

    input[type="checkbox"] {
        appearance: none;
        background-color: #fff;
        border: 1px solid white;
        margin: 0;
        font: inherit;
        color: #FFA000;
        width: 22px;
        height: 22px;
        border-radius: 90px;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    input[type="checkbox"]::before {
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 90px;
        // transitions
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);

        /* Windows High Contrast Mode */
        background-color: #FFA000;
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }






    .modal-custom {
        max-width: 900px !important;
        background-color: white !important;
        border-radius: 13px;
        width: 100%;

        .dm-span-close {
            position: relative; 
            color: white;
            padding-right: 20px; 
            //display: none;
            //top: 120px; 
            bottom: -120px; 
            cursor:default;
        }

        .responsive-iframe {
            border: 0px;
            max-width: 100% !important;
            height: 800px;
            width: 100%;
        }
    }


    .tooltip-container {
        position: relative;
        cursor: pointer;
    }
    //ocultar el tooltip
    .tooltip-container .tooltip-one {
        display: none;
    }
    .tooltip-trigger:hover + .tooltip-one {
        display: block;
    }

    .tooltip-one {
        padding: 18px 32px;
        background: #00000099;
        position: absolute;
        
        right: 10px;
        min-width: 300px;
        max-width: 320px;
        border-radius: 5px;
        text-align: center;
        color: white;
        filter: drop-shadow(0 3px 5px #000);
        line-height: 1.5;
        z-index: 10;
    }
    .tooltip-top {
        top: 10px;
    }
    .tooltip-bottom {
        bottom: 10px !important;
    }

</style>