<template>
    <div id="hc--navbar">
        <!-- Navbar -->
        <b-navbar id="mo_navbar" toggleable="md" class="px-0 px-md-3 px-xl-6 py-3" type="light" variant="white">

            <b-navbar-brand id="mo_navbar__title" @click="redirect('home')" class="mr-lg-5" 
                style="max-width: 220px; cursor: pointer;">
                <div>
                    <div class="d-inline-block mx-2" style="max-width: 120px;">
                        <img :src="img_logo" class="img-fluid">
                    </div>
                </div>
            </b-navbar-brand>

            <div class="h-100 ml-auto d-none d-md-flex justify-content-end align-items-center" v-if="false">

                <b-navbar-nav class="align-middle">
                    <b-nav-item class="cursor-pointer">
                        <span class="text-primary hc--description-sm">Conócenos</span>
                    </b-nav-item>
                    <b-nav-item class="cursor-pointer">
                        <span class="text-primary hc--description-sm">¿Cómo funciona?</span>
                    </b-nav-item>
                    <b-nav-item class="cursor-pointer">
                        <span class="text-primary hc--description-sm">Sucursales</span>
                    </b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="align-middle">
                    <b-nav-item>
                        <button class="btn bg-warning bg-warning-gradient" style="border-radius: 13px; color: white;">
                            <span class=" hc--description-sm text-white">Calculadora</span>
                        </button>
                    </b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="align-middle">
                    <b-nav-item>
                        <button class="btn btn-outline-warning text-hover-white" style="border-radius: 13px;">
                            <span class="hc--description-sm text-warning">Portal cliente</span>
                        </button>
                    </b-nav-item>
                </b-navbar-nav>
            </div>

            <b-button v-b-toggle.sidebar-right variant="link" class="d-md-none">
                <b-icon id="mo_navbar__menu_icon" icon="list" scale="2" variant="light"></b-icon>
            </b-button>
        </b-navbar>

        <!-- Sidebar -->
        <b-sidebar id="sidebar-right" bg-variant="warning" right backdrop shadow no-slide>
            <div class="row h-100 d-flex align-items">
                <div class="col-12">
                    <div class="mo_item py-2 py-md-3 px-3 px-xl-5">
                        <span @click="redirect('home')" class="hc--title-sm">
                            Inicio
                        </span>
                    </div>
                </div>

                <div class="col-12 mt-auto pb-4">
                    <div class="mb-auto d-flex justify-content-start pb-3 px-3 px-xl-5">
                        <div class="d-inline-block mx-2" style="max-width: 100px;">
                            <!-- <img :src="img_hircasa" class="img-fluid"> -->
                        </div>
                        <div class="d-inline-block mx-2" style="max-width: 100px;">
                            <!-- <img :src="img_hircasa" class="img-fluid"> -->
                        </div>
                    </div>
                    <div class="px-3 px-xl-5">
                        <span class="hc--description-sm text-white">
                            ©2022 Hircasa. All Rights Reserved
                        </span>
                    </div>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    // IMAGES
    import img_logo from '../assets/images/logo.png'

    // jQuery
    import $ from 'jquery'

    export default {
        name: 'mo-navbar',
        props: {
            msg: String
        },
        data() {
            return {
                img_logo
            }
        },
        computed: {
            current_route_name() {
                return this.$route.name
            }
        },
        methods: {
            redirect(route_name) {
                if(this.current_route_name !== route_name) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: route_name
                    })
                }
            }
        }
    }
</script>

<style lang="scss">
    li {
        list-style-type: none;
    }
    #hc--navbar {
        @media (min-width: 1200px) {
            .px-xl-6 {
                padding-left: 6rem !important;
                padding-right: 6rem !important;
            }    
        }
    }
    .dropdown-toggle::after {
        display:none;
    }

    .mo_navbar__scroll {
        background-color: #000000bb;
        box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
        -webkit-box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
        -moz-box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
    }

    .b-sidebar {
        width: 240px !important;
        height: 100vh !important;

        .b-sidebar-header {

            button {
                margin-left: auto;
                margin-right: 0px !important;

                color: white !important;

                svg {
                    font-size: 44px;
                }
            }
        }

        .mo_item {
            cursor: pointer;

            span {
                font-family: 'Montserrat-Bold';
                color: white !important;
            }
            span:hover {
                text-decoration: underline;
                text-decoration-style: simple;
            }
        }
    }
    
</style>
