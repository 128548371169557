import Vue from "vue"

Vue.filter('amountMoney', (value, float_digits = 2, dolar = false, point = true) => {

	let amount = null
	let elements = parseFloat(value).toFixed(float_digits).split('.')

	elements[0] = elements[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

	let char_join = (point) ? '.' : ','
	amount = (dolar) ? `$${elements.join(char_join)}` : `${elements.join(char_join)}`
	
	return amount

})

Vue.filter('upperCase', (value) => {
	return value.toString().toUpperCase()
})

Vue.filter('lowerCase', (value) => {
	return value.toString().toLowerCase()
})